<template>
  <div class="clue-follow">
    <Back title="线索跟进"></Back>
    <main>
      <van-form @submit="onSubmit">
        <van-field readonly clickable label="线索状态" :value="clueStatusName" placeholder="选择线索状态"
          @click="showPicker = true" v-if="clueStatus != 3" />
        <van-field v-model="remarkContent" rows="4" autosize name="remarkContent" label="跟进情况" type="textarea"
          maxlength="100" placeholder="请输入跟进情况" show-word-limit clearable v-if="(clueStatus != 4)" />
        <van-field readonly clickable label="流失原因" :value="loseReasonName" placeholder="选择流失原因" @click="showLose = true"
          v-if="(clueStatus == 4)" />
        <!-- 提交 -->
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
      <!-- 状态弹框 -->
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker show-toolbar :columns="objToOpt(clueStatusObj)" @cancel="showPicker = false" @confirm="onConfirm" />
      </van-popup>
      <!-- 流失原因弹框 -->
      <van-popup v-model="showLose" round position="bottom">
        <van-picker show-toolbar :columns="loseList" @cancel="showLose = false" @confirm="onLoseConfirm" />
      </van-popup>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import { Toast } from "vant";
import {
  getClueInfo,
  getClueChange,
  getLoseList,
} from "@/api/clue/clue"
import {
  clueStatusObj,
} from "@/db/clue";
import {
  objToOpt,
} from "@/utils/utils"
export default {
  components: { Back },
  data() {
    return {
      clueStatusObj,
      objToOpt,
      clueId: this.$route.query.clueId,
      loseList: [],
      clueInfo: "",
      clueStatus: "",
      clueStatusName: "",
      loseReason: "",
      remarkContent: '',//跟进备注
      showPicker: false,
      showLose: false,
    };
  },
  created() {
    this.getClueInfo();
    this.getLoseList();
  },
  computed: {
    loseReasonName() {
      if (this.loseList && this.loseReason) {
        let list = this.loseList.filter(i => i.value == this.loseReason)
        return list[0].text
      } else {
        return ""
      }
    }
  },
  methods: {
    // 【请求】获取客户详情
    getClueInfo() {
      let clueId = this.clueId;
      getClueInfo(clueId).then((res) => {
        if (res.success) {
          this.clueInfo = res.data;
          this.clueStatus = res.data.clueStatus;
          this.clueStatusName = clueStatusObj[res.data.clueStatus];
          this.loseReason = res.data.loseReason;
        }
      });
    },

    // 【请求】获取流失原因
    getLoseList() {
      getLoseList().then((res) => {
        if (res.success) {
          let list = res.list;
          this.loseList = JSON.parse(JSON.stringify(list).replace(/codeValue/g, "value").replace(/codeLabel/g, "text")) //修改对象属性名
        }
      });
    },

    // 【监听】选中线索状态
    onConfirm(value) {
      this.clueStatusName = value.text
      this.clueStatus = value.value;
      this.showPicker = false;
    },

    // 【监听】选中流失原因
    onLoseConfirm(value) {
      this.loseReasonName = value.text
      this.loseReason = value.value;
      this.showLose = false;
    },


    // 【监听】提交
    onSubmit(values) {
      let data = {
        clueId: this.clueId,
        remarkContent: values.remarkContent,
        consultantUserId: this.clueInfo.consultantUserId,
        oldConsultantUserId: this.clueInfo.oldConsultantUserId,
        clueStatus: this.clueStatus,
        oldCluestatus: this.clueInfo.clueStatus,
        deptId: this.clueInfo.deptId,
        loseReason: this.loseReason,
      }
      getClueChange(data).then(res => {
        if (res.success) {
          Toast('新增跟进成功');
          this.$router.push("/clue/clueInfo?clueId=" + this.clueId);
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.clue-follow {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
}
</style>